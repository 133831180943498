import { ColumnDef } from '@tanstack/react-table';
import AdvanceTable from 'components/base/AdvanceTable';
import AdvanceTableFooter from 'components/base/AdvanceTableFooter';
import { EpochVotingLeaderboardRecord } from 'providers/VortexDataProvider';

export const createEpochVotingLeaderboardTableColumns = (
  idealNumVotes: number | undefined
): ColumnDef<EpochVotingLeaderboardRecord>[] => {
  return [
    {
      accessorFn: (_row, index) => {
        if (index === 0) return '';
        return index;
      },
      header: 'TVC Ranking',
      meta: {
        cellProps: { className: 'text-body' }
      }
    },
    {
      accessorKey: 'nodeName',
      header: 'Validator',
      meta: {
        cellProps: { className: 'text-body' }
      }
    },
    {
      accessorKey: 'nodeAddress',
      header: 'Validator Address',
      cell: ({ cell, row }) => {
        return (
          <a
            href={`/vote-history/${row.original.voteAddress}`}
            target="_blank"
            rel="noreferrer"
          >
            {row.original.nodeAddress}
          </a>
        );
      },
      meta: {
        cellProps: { className: 'text-body' }
      }
    },
    {
      accessorKey: 'earnedCredits',
      header: 'Earned TVC Credits',
      meta: {
        cellProps: { className: 'text-body' }
      }
    },
    {
      accessorKey: 'votedSlots',
      header: 'Voted Slots',
      meta: {
        cellProps: { className: 'text-body' }
      }
    },
    {
      accessorFn: row => {
        return (row.earnedCredits / row.votedSlots).toFixed(3);
      },
      header: 'Avg credits / voted slot',
      meta: {
        cellProps: { className: 'text-body' }
      }
    },
    {
      accessorFn: row => {
        return (row.totalLatency / row.votedSlots).toFixed(3);
      },
      header: 'Avg latency / voted slot',
      meta: {
        cellProps: { className: 'text-body' }
      }
    },
    {
      accessorFn: row => row.continent || 'N/A',
      header: 'Region',
      meta: {
        cellProps: { className: 'text-body' }
      }
    },
    {
      accessorFn: row => row.country || 'N/A',
      header: 'Country',
      meta: {
        cellProps: { className: 'text-body' }
      }
    },
    {
      accessorFn: row => row.datacenter || 'N/A',
      header: 'Datacenter',
      meta: {
        cellProps: { className: 'text-body' }
      }
    },
    {
      accessorFn: row => {
        if (idealNumVotes === undefined) {
          return 'NA';
        } else {
          return idealNumVotes - row.votedSlots;
        }
      },
      header: 'Missed Voted Slots',
      meta: {
        cellProps: { className: 'text-body' }
      }
    }
  ];
};

const EpochLeaderboardTable = () => {
  return (
    <div>
      <AdvanceTable tableProps={{ className: 'phoenix-table fs-9' }} />
      <AdvanceTableFooter pagination />
    </div>
  );
};

export default EpochLeaderboardTable;
