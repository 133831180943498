import { faSquare } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { useContext, useEffect, useMemo } from 'react';
import { Col, Row } from 'react-bootstrap';
import VoteLatencyPieChart, {
  useChartStats
} from './charts/e-charts/VoteQualityPieChart';
import { VortexDataContext } from 'providers/VortexDataProvider';
import { useAppContext } from 'providers/AppProvider';

interface VoteLatencyItem {
  value: number;
  label: string;
  color: string;
  className?: string;
}

const VoteLatencyItem = ({
  value,
  label,
  color,
  className
}: VoteLatencyItem) => {
  return (
    <div
      className={classNames(
        className,
        'd-flex flex-column flex-center align-items-sm-start flex-md-row justify-content-md-between flex-xxl-column p-3 ps-sm-3 ps-md-4 p-md-3 h-100'
      )}
    >
      <div className="d-flex align-items-center mb-1">
        <FontAwesomeIcon
          icon={faSquare}
          className={`fs-11 me-2 text-${color}`}
          transform="up-2"
        />
        <span className="mb-0 fs-9 text-body">{label}</span>
      </div>
      <h3 className="fw-semibold ms-xl-3 ms-xxl-0 pe-md-2 pe-xxl-0 mb-0 mb-sm-3">
        {value}
      </h3>
    </div>
  );
};

const VoteLatencyOverview = ({
  epoch,
  voteId
}: {
  epoch: number;
  voteId: string;
}) => {
  const {
    getThemeColor,
    config: { isDark }
  } = useAppContext();
  const context = useContext(VortexDataContext);
  const EPOCH = epoch;
  const VOTE_ID = voteId;
  const IDEAL_RECORD_KEY = '11111111111111111111111111111111';

  useEffect(() => {
    if (
      context?.epochVoteRecordState &&
      !context.epochVoteRecordState.isLoading &&
      !context.epochVoteRecordState.data.size
    ) {
      context.epochVoteRecordState.fetchData(VOTE_ID, EPOCH);
      context.epochVoteRecordState.fetchData(IDEAL_RECORD_KEY, EPOCH);
    }
  }, [context]);

  const clusterData = context?.epochVoteRecordState.data
    .get(IDEAL_RECORD_KEY)
    ?.get(EPOCH);
  const tableData = context?.epochVoteRecordState.data.get(VOTE_ID)?.get(EPOCH);

  const chartStats = useChartStats({ clusterData, tableData });
  const dataList = useMemo(() => {
    if (chartStats === undefined) {
      return [];
    }

    return [
      {
        name: 'MIN (1)',
        color: 'primary',
        value: chartStats.minLatency
      },

      {
        name: 'FAST (2)',
        color: 'secondary',
        value: chartStats.fastLatency
      },

      {
        name: 'DELAYED (3-6)',
        color: 'info',
        value: chartStats.delayLatency
      },

      {
        name: 'SLOW (>6)',
        color: 'warning',
        value: chartStats.slowLatency
      },

      {
        name: 'MISSED',
        color: 'danger',
        value: chartStats.skippedSlots
      }
    ];
  }, [chartStats]);

  const total = useMemo(() => {
    return dataList.reduce((acc, next) => acc + next.value, 0);
  }, [dataList]);

  return (
    <>
      <Row className="my-4">
        <h3>Vote Latency</h3>
        <p className="text-body-tertiary">
          Vote latency relative to optimal inclusion
        </p>
      </Row>
      <Row className="my-4">
        <Col xs={12} sm={7} md={8} xxl={8} className="mb-md-3 mb-lg-0">
          <Row className="g-0">
            {dataList.map((item, index) => (
              <Col xs={6} xl={4} key={item.name}>
                <VoteLatencyItem
                  value={item.value}
                  label={item.name}
                  color={item.color}
                  className={classNames('border-translucent', {
                    'border-bottom border-end': index === 0,
                    'border-bottom border-end-md-0 border-end-xl': index === 1,
                    'border-bottom border-end border-end-md border-end-xl-0':
                      index === 2,
                    'border-end-xl border-bottom border-bottom-xl-0':
                      index === 3,
                    'border-end': index === 4
                  })}
                />
              </Col>
            ))}
          </Row>
        </Col>
        <Col sm={5} md={4} xxl={4} className="my-3 my-sm-0">
          <div className="position-relative d-flex flex-center mb-sm-4 mb-xl-0 echart-contact-by-source-container mt-sm-7 mt-lg-4 mt-xl-0">
            <VoteLatencyPieChart
              style={{ height: 245, width: '100%' }}
              voteId={voteId}
              epoch={EPOCH}
            />
            <div
              className="position-absolute rounded-circle bg-primary-subtle top-50 start-50 translate-middle d-flex flex-center"
              style={{ height: '100px', width: '100px' }}
            >
              <h3 className="mb-0 text-primary-dark fw-bolder">{total}</h3>
            </div>
          </div>
        </Col>
      </Row>
    </>
  );
};

export default VoteLatencyOverview;
