import { ColumnDef } from '@tanstack/react-table';
import AdvanceTable from 'components/base/AdvanceTable';
import AdvanceTableFooter from 'components/base/AdvanceTableFooter';
import { latencyToCredits } from 'helpers/utils';

export const createEpochVoteRecordTableColumns = (
  voteId: string,
  epoch: number,
  epochStartSlot: number | undefined,
  chunkSize: number,
  idealLatencies: number[][] | undefined
): ColumnDef<number[]>[] => {
  return [
    {
      cell: ({ cell, row }) => {
        if (epochStartSlot === undefined) return;
        let bucketIndex = row.index;
        let slotRange = `${
          (epochStartSlot + bucketIndex * chunkSize) / chunkSize
        }000..999`;
        return (
          <a
            href={`/vote-history/${voteId}/epoch/${epoch}/bucket/${bucketIndex}`}
            target="_blank"
            rel="noreferrer"
          >
            {slotRange}
          </a>
        );
      },
      header: 'Slot Range',
      meta: {
        cellProps: { className: 'text-body' }
      }
    },
    {
      accessorFn: latencies => {
        const numVotedSlots = latencies.reduce(
          (acc, next) => acc + (next === 0 ? 0 : 1),
          0
        );
        if (numVotedSlots === 0) return 0;
        return (
          latencies.reduce((acc, next) => acc + next, 0) / numVotedSlots
        ).toFixed(3);
      },
      header: 'Avg Latency',
      meta: {
        cellProps: { className: 'text-body' }
      }
    },
    {
      accessorFn: (_row, index) => {
        let idealLatencyBucket = idealLatencies?.at(index);
        if (idealLatencyBucket === undefined) return 0;
        const numVotedSlots = idealLatencyBucket.reduce(
          (acc, next) => acc + (next === 0 ? 0 : 1),
          0
        );
        if (numVotedSlots === 0) return 0;
        return (
          idealLatencyBucket.reduce((acc, next) => acc + next, 0) /
          numVotedSlots
        ).toFixed(3);
      },
      header: 'Optimal Avg Latency',
      meta: {
        cellProps: { className: 'text-body' }
      }
    },
    {
      accessorFn: latencies => {
        return latencies.reduce(
          (acc, latency) => acc + latencyToCredits(latency),
          0
        );
      },
      header: 'Earned TVC Credits',
      meta: {
        cellProps: { className: 'text-body' }
      }
    },
    {
      accessorFn: (latencies, index) => {
        let idealLatencyBucket = idealLatencies?.at(index);
        if (idealLatencyBucket === undefined || idealLatencyBucket.length === 0)
          return 0;
        return idealLatencyBucket.reduce(
          (acc, latency) => acc + latencyToCredits(latency),
          0
        );
      },
      header: 'Optimal TVC Credits',
      meta: {
        cellProps: { className: 'text-body' }
      }
    },
    {
      accessorFn: (latencies, index) => {
        let idealLatencyBucket = idealLatencies?.at(index);
        if (idealLatencyBucket === undefined || idealLatencyBucket.length === 0)
          return 0;
        const earnedCredits = latencies.reduce(
          (acc, latency) => acc + latencyToCredits(latency),
          0
        );
        const optimalCredits = idealLatencyBucket.reduce(
          (acc, latency) => acc + latencyToCredits(latency),
          0
        );
        return optimalCredits - earnedCredits;
      },
      header: 'Missed TVC Credits',
      meta: {
        cellProps: { className: 'text-body' }
      }
    }
  ];
};

const EpochVoteRecordTable = () => {
  return (
    <div>
      <AdvanceTable tableProps={{ className: 'phoenix-table fs-9' }} />
      <AdvanceTableFooter pagination />
    </div>
  );
};

export default EpochVoteRecordTable;
